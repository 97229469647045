import * as THREE from 'three';
import Particle from "./Particle";

class LightParticle extends Particle {

  constructor(config) {
    super(config);

    const t = this;

    t.hAngle = Math.random()*360;
    t.vAngle = Math.random()*360;
    t.vAngle2 = Math.random()*360;
    t.vAngle3 = Math.random()*360;

    t.hVel = 0.01;
    t.maxVel = 0.01;
    t.hAcc = 0.001;
    t.setTimer();
  }

  setTimer() {
    const t = this;
    t.timer = new Date().getTime();
    t.changeDirDur = 5000 + Math.random()*10000;
  }

  run() {
    const t = this;
    t.update();
    t.render();
  }

  update() {
    const t = this;

    // orbit center with slight ellipse
    t.pos.x = Math.sin(t.hAngle) * t.config.maxD;
    t.pos.z = Math.cos(t.hAngle) * (t.config.maxD * 0.66);

    // oscillate vertically using multiple waves
    t.pos.y = (Math.sin(t.vAngle) * 30) + (Math.sin(t.vAngle2) * 30) + (Math.sin(t.vAngle3) * 30);

    // horizontal
    t.hVel += t.hAcc;

    if (t.hVel > t.maxVel) {
      t.hVel = t.maxVel;
    } else if (t.hVel < -t.maxVel) {
      t.hVel = -t.maxVel;
    }

    t.hAngle += t.hVel;

    if (new Date().getTime() >= t.timer + t.changeDirDur) {
      t.hAcc *= -1;
      t.setTimer();
    }

    // vertical
    t.vAngle += 0.02;
    t.vAngle2 += 0.03;
    t.vAngle3 += 0.04;
  }

}

export default LightParticle;
