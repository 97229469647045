import * as THREE from 'three';

class Particle {

  constructor(config) {
    const t = this;

    t.config = config;
    t.maxD = config.maxD || 200;

    t.acc = new THREE.Vector3(0, 0, 0);

    t.vel = new THREE.Vector3(
      -1 + Math.random()*2,
      -1 + Math.random()*2,
      -1 + Math.random()*2
    );

    t.pos = new THREE.Vector3(
      -t.maxD + Math.random()*t.maxD*2,
      -t.maxD + Math.random()*t.maxD*2,
      -t.maxD + Math.random()*t.maxD*2
    );

    t.maxSpeed = 3;
    t.maxForce = 0.05;
  }

  run(particles, light) {
    const t = this;
    t.flock(particles, light);
    t.update();
    t.borders();
    t.render();
  }

  flock(particles, light) {
    const t = this;
    let sep = t.separate(particles);
    let ali = t.align(particles);
    let coh = t.cohesion(particles);

    sep.multiplyScalar(1.5);
    ali.multiplyScalar(0.5);
    coh.multiplyScalar(0.3);

    t.applyForce(sep);
    t.applyForce(ali);
    t.applyForce(coh);

    // steer towards light
    let lightForce = t.seek(light.pos.clone());
    lightForce.multiplyScalar(1);
    t.applyForce(lightForce);
  }

  /*
    Check for nearby particles and steer away
    */
  separate(particles) {
    const t = this;
    let desiredSep = 25;
    let steer = new THREE.Vector3(0, 0, 0);
    let count = 0;
    let i = particles.length;

    while (i--) {
      let d = t.pos.distanceTo(particles[i].pos);

      if ((d > 0) && (d < desiredSep)) {
        let dif = t.pos.clone().sub(particles[i].pos);
        dif.normalize();
        dif.divideScalar(d);
        steer.add(dif);
        count++;
      }
    }

    if (count > 0) {
      steer.divideScalar(count);
    }

    if (steer.length() > 0) {
      steer.normalize();
      steer.multiplyScalar(t.maxSpeed);
      steer.sub(t.vel);
      steer.clampScalar(-t.maxForce, t.maxForce);
    }

    return steer;
  }

  /*
    For every particle calculate average velocity
    */
  align(particles) {
    let t = this;
    let neighborDist = 50;
    let sum = new THREE.Vector3(0, 0, 0);
    let count = 0;
    let i = particles.length;

    while (i--) {
      let d = t.pos.distanceTo(particles[i].pos);

      if ((d > 0) && (d < neighborDist)) {
        sum.add(particles[i].vel);
        count++;
      }
    }

    if (count > 0) {
      sum.divideScalar(count);
      sum.normalize();
      sum.multiplyScalar(t.maxSpeed);

      let steer = sum.sub(t.vel);
      steer.clampScalar(-t.maxForce, t.maxForce);
      return steer;
    } else {
      return new THREE.Vector3(0, 0, 0);
    }
  }

  /*
    For average location of nearby particles,
    steer toward that location
    */
  cohesion(particles) {
    let t = this;
    let neighborDist = 50;
    let sum = new THREE.Vector3(0, 0, 0);
    let count = 0;
    let i = particles.length;

    while (i--) {
      let d = t.pos.distanceTo(particles[i].pos);
      if ((d > 0) && (d < neighborDist)) {
        sum.add(particles[i].pos);
        count++;
      }
    }

    if (count > 0) {
      sum.divideScalar(count);
      return t.seek(sum);
    } else {
      return new THREE.Vector3(0, 0, 0);
    }
  }

  applyForce(force) {
    // We could add mass here if we want A = F / M
    this.acc.add(force);
  }

  update() {
    const t = this;
    t.vel.add(t.acc);
    t.vel.clampScalar(-t.maxSpeed, t.maxSpeed);
    t.pos.add(t.vel);

    // set back to 0
    t.acc.multiplyScalar(0);
  }

  borders() {
    const t = this;
    const d = t.pos.distanceTo(new THREE.Vector3());

    // are we getting too far out there?
    if (d > t.config.maxD * 2) {
      // steer back toward center of scene
      t.applyForce(t.seek(new THREE.Vector3()));
    }
  }

  render() {
    const t = this;
    t.config.mesh.position.x = t.pos.x;
    t.config.mesh.position.y = t.pos.y;
    t.config.mesh.position.z = t.pos.z;
  }

  seek(target) {
    const t = this;

    let desired = target.sub(t.pos);
    desired.normalize();
    desired.multiplyScalar(t.maxSpeed);

    let steer = desired.sub(t.vel);
    steer.clampScalar(-t.maxForce, t.maxForce);
    return steer;
  }
}

export default Particle;
