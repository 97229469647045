import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { debounce, degToRad } from '../helpers/utils';
import Particle from './Particle';
import Particles from './Particles';
import LightParticle from './LightParticle';
// import fragmentShader from '../shaders/fragment.glsl';

const DEBUG = location.hash.substring(1) === 'debug' || false;

class Exp {

  constructor(config) {
    this.config = config;
  }

  init() {
    const t = this;

    t.updateStageSize();
    t.initScene();
    t.initLayout();
    t.initAnimation();
    t.initControls();
  }

  initScene() {
    const t = this;

    let geometry;
    let material;
    let mesh;
    const sceneRadius = 200;

    t.scene = new THREE.Scene();

    if (DEBUG) {
      t.scene.background = new THREE.Color(0xcccccc);
    } else {
      // t.scene.background = new THREE.Color(0x111111);
      t.scene.background = new THREE.Color(0x060606);
    }

    t.camera = new THREE.PerspectiveCamera(75, t.stageWidth / t.stageHeight, 0.1, 1000);
    t.camera.position.z = sceneRadius * 1.75;

    t.renderer = new THREE.WebGLRenderer();
    // t.renderer = new THREE.WebGLRenderer({
    //   antialias: true
    // });

    t.config.el.appendChild(t.renderer.domElement);

    // particles ----------------------

    t.particlesGroup = new THREE.Group();
    t.scene.add(t.particlesGroup);

    material = new THREE.MeshPhongMaterial({ color: 0x999999 });

    t.particles = new Particles();

    var lightSphere = new THREE.SphereBufferGeometry(10, 24, 24);
    var light = new THREE.PointLight(0xffffff, 1, sceneRadius);
    light.add(new THREE.Mesh(lightSphere, new THREE.MeshBasicMaterial({ color: 0xffffff })));

    t.particles.addLight(new LightParticle({
      mesh: light,
      maxD: sceneRadius
    }));
    t.particlesGroup.add(light);

    let i = 400;
    while (i--) {

      geometry = new THREE.SphereGeometry(2, 8, 8);
      mesh = new THREE.Mesh(geometry, material);

      t.particles.addParticle(new Particle({
        mesh: mesh,
        maxD: sceneRadius
      }));

      t.particlesGroup.add(mesh);
    }

    // boundary sphere -----------

    if (DEBUG) {
      var wireframe;
      let line;
      let matLineBasic;

      geometry = new THREE.SphereGeometry(sceneRadius, 32, 32);
      wireframe = new THREE.WireframeGeometry(geometry);
      matLineBasic = new THREE.LineBasicMaterial();
      line = new THREE.LineSegments(wireframe);
      line.material.opacity = 0.25;
      line.material.transparent = 0.25;
      t.particlesGroup.add(line);
      // t.scene.add(line);
    } else {


    }


    // light ----------------


    // var sphere = new THREE.SphereBufferGeometry( 2, 16, 8 );

    // t.light = new THREE.PointLight(0xffffff, 1, sceneRadius*2);
    // t.light.add(new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xffffff } ) ));
    // t.scene.add(t.light);


    // shader
    // https://tympanus.net/codrops/2022/03/07/creating-a-risograph-grain-light-effect-in-three-js/
    // this.uniforms = THREE.UniformsUtils.merge([
    //   THREE.ShaderLib.lambert.uniforms,
    //   {
    //     uColor: {
    //       value: new THREE.Color(0x51b1f5)
    //     },
    //     uNoiseCoef: {
    //       value: 3.5
    //     },
    //     uNoiseScale: {
    //       value: 0.8
    //     }
    //   }
    // ])

    // material = new THREE.ShaderMaterial({
    //   vertexShader: THREE.ShaderLib.lambert.vertexShader,
    //   fragmentShader: glslify(fragmentShader),
    //   uniforms: this.uniforms,
    //   lights: true,
    //   transparent: true
    // })
  }

  initAnimation() {
    const t = this;

    let animate = () => {
      t.requestAnimationFrameId = requestAnimationFrame(animate);
      t.updateAnimation();
    };

    animate();
  }

  updateAnimation() {
    const t = this;

    t.particles.run();

    t.particlesGroup.rotation.x += 0.001;
    t.particlesGroup.rotation.y += 0.001;

    t.renderer.render(t.scene, t.camera);
  }

  updateStageSize() {
    const t = this;
    t.stageWidth = window.innerWidth;
    t.stageHeight = window.innerHeight;
  }

  initLayout() {
    const t = this;

    window.addEventListener('resize', debounce(() => {
      t.updateLayout();
    }, 250));

    t.updateLayout();
  }

  updateLayout() {
    const t = this;
    t.updateStageSize();

    t.camera.aspect = t.stageWidth / t.stageHeight;
    t.camera.updateProjectionMatrix();
    t.renderer.setSize(t.stageWidth, t.stageHeight);
  }

  initControls() {
    const t = this;
    t.controls = new OrbitControls(t.camera, t.renderer.domElement);

    if (!DEBUG) {
      t.controls.enableZoom = false;
      t.controls.enablePan = false;
    }

    // t.controls.enableKeys = false;
    // t.controls.enableDamping = true;
    // t.controls.dampingFactor = 0.1;
    // t.controls.rotateSpeed = 0.1;
    // t.controls.minPolarAngle = THREE.Math.degToRad(1);
    // t.controls.maxPolarAngle = Math.PI - THREE.Math.degToRad(1);
    // t.controls.minDistance = t.config.scene.controlsMinZoom;
    // t.controls.maxDistance = t.config.scene.controlsMaxZoom;
  }
}

export default Exp;
