import './index.scss';
import Exp from './components/Exp';

const init = () => {
  const exp = new Exp({
    el: document.getElementById('bg'),
  });

  exp.init();

  //  --

  const map = (x, inMin, inMax, outMin, outMax) => (
    (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
  );

  const panelEl = document.getElementById('panel');
  if (!panelEl) {
    return;
  }

  panelEl.addEventListener('mousemove', (e) => {
    const x = e.clientX - panelEl.offsetLeft + document.body.scrollLeft;
    const y = e.clientY - panelEl.offsetTop + document.body.scrollTop;
    const amt = 8;
    const rY = map(x, 0, panelEl.offsetWidth, -amt, amt);
    const rX = map(y, 0, panelEl.offsetHeight, -amt, amt);
    panelEl.style.transform = `rotateX(${rX}deg) rotateY(${rY}deg)`;
  });

  panelEl.addEventListener('mouseleave', (e) => {
    panelEl.style.transform = `rotateX(0deg) rotateY(0deg)`;
  });
};

window.addEventListener('DOMContentLoaded', () => {
  init();
});
