class Particles {

  constructor(config) {
    this.config = config;
    this.light = null;
    this.particles = [];
  }

  addParticle(particle) {
    const t = this;
    t.particles.push(particle);
  }

  addLight(light) {
    const t = this;
    t.light = light;
    t.addParticle(light);
  }

  run() {
    const t = this;
    let i = t.particles.length;

    while (i--) {
      t.particles[i].run(t.particles, t.light);
    }
  }
}

export default Particles;
